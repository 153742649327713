import React from 'react';

import WineAwesomenessLogo from '../client-logos/WineAwesomenessLogo';
import CaseStudySection from '../sections/CaseStudySection';

const WineAwesomenessBackground = ({ className }) => (
  <div
    className={className}
    style={{
      backgroundImage: `url("/static-images/wine-awesomeness_south-africa-piekenierskloof.jpg")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    &nbsp;
  </div>
);

const WineAwesomenessSection = ({ textSide = 'left' }) => (
  <CaseStudySection textSide={textSide} BackgroundCover={WineAwesomenessBackground}>
    <h2>
      <WineAwesomenessLogo className="inline-block h-5 w-auto mb-8" />
    </h2>
    <p className="text-xl mb-7">
      &quot;The customer support is fast and reliable. I would highly recommend Subscribe Pro to any company who wants reliable
      software to manage the subscription side of their business.&quot;
    </p>
    <p className="text-lg mb-10">
      Dale Slear
      <br />
      CTO
    </p>
    <a href="/contact" className="inline-flex btn btn-sp-green">
      Get in touch
    </a>
  </CaseStudySection>
);

export default WineAwesomenessSection;
