import React from 'react';

const TextBlock = ({ className, children }) => (
  <div className="w-full h-full ">
    <div className={`flex flex-col items-center justify-center py-14 px-12 text-center ${className}`}>{children}</div>
  </div>
);

const TwoColumnBackground = ({ textSide = 'left', className, BackgroundCover }) => (
  <div className={`h-full w-full flex-col lg:flex-row ${className}`}>
    <div className={`h-full w-full ${textSide === 'right' && `order-2`}`}>
      <div className="h-full w-full bg-sp-primary-lighter-grey ">&nbsp;</div>
    </div>
    <BackgroundCover className={`h-full w-full ${textSide === 'right' && `order-1`}`} />
  </div>
);

const CaseStudySection = ({ textSide = 'left', BackgroundCover, children }) => (
  <section className="lg:relative lg:h-[500px]">
    <TwoColumnBackground textSide={textSide} className="hidden lg:flex lg:absolute" BackgroundCover={BackgroundCover} />
    <div className="h-full lg:relative flex max-w-screen-2xl mx-auto ">
      <TextBlock
        className={`bg-sp-primary-lighter-grey lg:bg-transparent lg:w-[50%] mh-[500px] lg:h-full ${textSide === 'right' && `lg:ml-[50%]`}`}
      >
        {children}
      </TextBlock>
    </div>
    <BackgroundCover className="w-full lg:hidden h-[500px]" />
  </section>
);

export default CaseStudySection;
