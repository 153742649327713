import React from 'react';

import UsWaterSystemsLogo from '../client-logos/UsWaterSystemsLogo';
import CaseStudySection from '../sections/CaseStudySection';

const UsWaterSystemsBackground = ({ className }) => (
  <div
    className={className}
    style={{
      backgroundImage: `url("/static-images/us-water-systems_glass-of-water-next-to-water-filters.webp")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    &nbsp;
  </div>
);

const UsWaterSystemsSection = ({ textSide = 'right' }) => (
  <CaseStudySection textSide={textSide} BackgroundCover={UsWaterSystemsBackground}>
    <h2>
      <UsWaterSystemsLogo className="inline-block h-24 w-auto mb-8" />
    </h2>
    <p className="text-xl mb-7">
      &quot;Communication is king when it comes to recurring orders, and we no longer need to use a third-party service for email
      communications.&quot;
    </p>
    <p className="text-lg mb-10">
      Kristi Williamson
      <br />
      VP of Business Development
    </p>
    <a href="/contact" className="inline-flex btn btn-sp-green">
      Get in touch
    </a>
  </CaseStudySection>
);

export default UsWaterSystemsSection;
