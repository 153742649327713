import React from 'react';

import PrevinexLogo from '../client-logos/PrevinexLogo';
import CaseStudySection from '../sections/CaseStudySection';

const PrevinexBackground = ({ className }) => (
  <div
    className={className}
    style={{
      backgroundImage: `linear-gradient(to right, rgba(224,224,224,0), rgba(224,224,224,0.1), rgba(224,224,224,0.2), rgba(224,224,224,0.6)), url("/static-images/previnex_nourify_plus_and_super_vites.png")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    }}
  >
    &nbsp;
  </div>
);

const PrevinexSection = ({ textSide = 'right' }) => (
  <CaseStudySection textSide={textSide} BackgroundCover={PrevinexBackground}>
    <h2>
      <PrevinexLogo className="inline-block h-14 w-auto mb-8" />
    </h2>
    <p className="text-xl mb-7">
      &quot;Subscribe Pro’s staff listened to our needs and the service performs exactly as advertised. What has impressed me most
      is their expertise in tailoring the platform to meet our needs.&quot;
    </p>
    <p className="text-lg mb-10">
      Jeff Boomer
      <br />
      COO
    </p>
    <a href="/contact" className="inline-flex btn btn-sp-green">
      Get in touch
    </a>
  </CaseStudySection>
);

export default PrevinexSection;
