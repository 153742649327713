import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Header from '../components/Header';
import TopBrandsSection from '../components/sections/TopBrandsSection';
import RxbarSection from '../components/clients/RxbarSection';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import NaturesBakerySection from '../components/clients/NaturesBakerySection';
import CaseStudyTopperSection from '../components/sections/CaseStudyTopperSection';
import CoravinSection from '../components/clients/CoravinSection';
import PrevinexSection from '../components/clients/PrevinexSection';
import UsWaterSystemsSection from '../components/clients/UsWaterSystemsSection';
import UsWaterFiltersSection from '../components/clients/UsWaterFiltersSection';
import WineAwesomenessSection from '../components/clients/WineAwesomenessSection';

const ClientsPage = () => (
  <Layout>
    <Seo title="Clients" />

    <Header graphic="1">Clients</Header>

    <CaseStudyTopperSection textSide="left" />

    <CoravinSection textSide="right" />
    <PrevinexSection textSide="left" />
    <UsWaterSystemsSection textSide="right" />
    <NaturesBakerySection textSide="left" />
    <UsWaterFiltersSection textSide="right" />
    <WineAwesomenessSection textSide="left" />
    <RxbarSection textSide="right" />

    <TopBrandsSection containerClassName="max-w-[1170px] lg:mb-16" showCards />

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default ClientsPage;
