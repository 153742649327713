import React from 'react';

import CaseStudySection from '../sections/CaseStudySection';
import RxbarLogo from '../client-logos/RxbarLogo';

const RxbarBackground = ({ className }) => (
  <div
    className={className}
    style={{
      backgroundImage: `url("/static-images/rxbar-spring-banner-desktop.png")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    &nbsp;
  </div>
);

const RxbarSection = ({ textSide = 'right' }) => (
  <CaseStudySection textSide={textSide} BackgroundCover={RxbarBackground}>
    <h2>
      <RxbarLogo className="inline-block h-14 w-auto mb-8" />
    </h2>
    <p className="text-xl mb-7">
      &quot;Your team&apos;s service has been outstanding. We have been very happy with the response and appreciate the steps you
      have taken based on our feedback.&quot;
    </p>
    <p className="text-lg mb-10">
      Kim Galovich
      <br />
      Senior Web Manager
    </p>
    <a href="/contact" className="inline-flex btn btn-sp-green">
      Get in touch
    </a>
  </CaseStudySection>
);

export default RxbarSection;
