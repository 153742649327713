import React from 'react';

import UsWaterFiltersLogo from '../client-logos/UsWaterFiltersLogo';
import CaseStudySection from '../sections/CaseStudySection';

const UsWaterFiltersBackground = ({ className }) => (
  <div
    className={className}
    style={{
      backgroundImage: `url("/static-images/tier1_filter_assortment.webp")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    &nbsp;
  </div>
);

const UsWaterFiltersSection = ({ textSide = 'right' }) => (
  <CaseStudySection textSide={textSide} BackgroundCover={UsWaterFiltersBackground}>
    <h2>
      <UsWaterFiltersLogo className="inline-block h-16 w-auto mb-8" />
    </h2>
    <p className="text-xl mb-7">
      &quot;Subscription customers are so important to us because they are our most loyal. Trusting Subscribe Pro proved to be the
      right move, we are able to fulfill subscription orders in a very timely manner.&quot;
    </p>
    <p className="text-lg mb-10">
      Laura Subialka
      <br />
      Sr. Business Analyst
    </p>
    <a href="/contact" className="inline-flex btn btn-sp-green">
      Get in touch
    </a>
  </CaseStudySection>
);

export default UsWaterFiltersSection;
