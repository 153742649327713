import React from 'react';

const CaseStudiesGraphic = ({ className }) => (
  <svg className={className} width="695" height="352" viewBox="0 0 695 352" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.6" d="M289 156.477L327.734 195.188" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M269.633 156.477C280.329 156.477 289 147.811 289 137.122C289 126.432 280.329 117.766 269.633 117.766C258.937 117.766 250.266 126.432 250.266 137.122C250.266 147.811 258.937 156.477 269.633 156.477Z"
      stroke="#5BDCB4"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M347.102 233.9C357.798 233.9 366.469 225.234 366.469 214.544C366.469 203.854 357.798 195.189 347.102 195.189C336.405 195.189 327.734 203.854 327.734 214.544C327.734 225.234 336.405 233.9 347.102 233.9Z"
      stroke="#5BDCB4"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M424.57 40.3437C435.266 40.3437 443.937 31.6779 443.937 20.988C443.937 10.2982 435.266 1.63245 424.57 1.63245C413.874 1.63245 405.203 10.2982 405.203 20.988C405.203 31.6779 413.874 40.3437 424.57 40.3437Z"
      stroke="#5BDCB4"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M424.57 156.477C435.266 156.477 443.937 147.811 443.937 137.122C443.937 126.432 435.266 117.766 424.57 117.766C413.874 117.766 405.203 126.432 405.203 137.122C405.203 147.811 413.874 156.477 424.57 156.477Z"
      stroke="#5BDCB4"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M308.367 137.122H385.836" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M114.703 156.477C125.399 156.477 134.07 147.811 134.07 137.122C134.07 126.432 125.399 117.766 114.703 117.766C104.007 117.766 95.3359 126.432 95.3359 137.122C95.3359 147.811 104.007 156.477 114.703 156.477Z"
      stroke="#5BDCB4"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M153.438 137.122H230.906" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path opacity="0.6" d="M0 137.122H77.4686" stroke="url(#paint0_linear_702_158)" strokeWidth="3" strokeMiterlimit="10" />
    <path
      opacity="0.7"
      d="M694.68 21.176L617.211 21.176"
      stroke="url(#paint1_linear_702_158)"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.7" d="M540.148 21.176L462.68 21.176" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M579.508 40.3437C590.204 40.3437 598.875 31.6779 598.875 20.988C598.875 10.2982 590.204 1.63245 579.508 1.63245C568.812 1.63245 560.141 10.2982 560.141 20.988C560.141 31.6779 568.812 40.3437 579.508 40.3437Z"
      stroke="#5BDCB4"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M424.57 59.6993V98.4105" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M347.102 350.033C357.798 350.033 366.469 341.368 366.469 330.678C366.469 319.988 357.798 311.322 347.102 311.322C336.405 311.322 327.734 319.988 327.734 330.678C327.734 341.368 336.405 350.033 347.102 350.033Z"
      stroke="#5BDCB4"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M347.094 253.255V291.967" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <defs>
      <linearGradient
        id="paint0_linear_702_158"
        x1="76.9985"
        y1="137.199"
        x2="-7.42578e-08"
        y2="137.199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_702_158" x1="617.681" y1="21.099" x2="694.68" y2="21.0991" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default CaseStudiesGraphic;
