import React from 'react';

import CaseStudySection from './CaseStudySection';
import CaseStudiesGraphic from '../svg/CaseStudiesGraphic';

const CaseStudyTopperBackground = ({ className }) => (
  <div className={`flex items-center bg-sp-primary-dark ${className}`}>
    <CaseStudiesGraphic className="block w-full px-4 md:px-12" />
  </div>
);

const CaseStudyTopperSection = ({ textSide }) => (
  <CaseStudySection textSide={textSide} BackgroundCover={CaseStudyTopperBackground}>
    <div className="sm:px-24 lg:px-0">
      <h2 className="text-4xl mb-14 lg:text-left">We&apos;re proud to support some of the world&apos;s top brands</h2>
      <p className="text-lg lg:text-left">
        Whether you&apos;re looking to reduce churn, increase LTV, or integrate coupon codes to incentivize new customers,
        we&apos;ve done it all.
        <br />
        <br />
        But don&apos;t take our word for it: Take a look at the portfolio of brands we&apos;ve been able to support across a wide
        range of industries.
      </p>
    </div>
  </CaseStudySection>
);

export default CaseStudyTopperSection;
