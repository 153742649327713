import React from 'react';

const CoravinLogo = ({ className }) => (
  <svg className={className} width="735" height="124" viewBox="0 0 735 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M260.955 61.9521C260.955 65.409 260.285 68.7079 258.954 71.7422C257.629 74.8032 255.84 77.4872 253.592 79.7278C251.335 81.9721 248.688 83.7881 245.617 85.0959C239.529 87.7342 232.153 87.7342 226.077 85.0959C223.031 83.7881 220.344 81.9721 218.087 79.7278C215.859 77.4872 214.062 74.8032 212.733 71.7422C211.411 68.7079 210.744 65.409 210.744 61.9521C210.744 58.5066 211.411 55.2248 212.733 52.181C214.062 49.1219 215.859 46.4512 218.087 44.1916C220.344 41.9606 223.031 40.1503 226.077 38.8292C229.126 37.5082 232.398 36.8267 235.838 36.8267C239.285 36.8267 242.575 37.5082 245.617 38.8292C248.688 40.1503 251.335 41.9606 253.592 44.1916C255.83 46.4512 257.621 49.1219 258.954 52.181C260.285 55.2248 260.955 58.5066 260.955 61.9521ZM258.414 39.3889C255.499 36.4916 252.071 34.1807 248.234 32.5398C240.455 29.2123 231.037 29.2599 223.41 32.5398C219.603 34.1807 216.19 36.4916 213.298 39.3889C210.422 42.2823 208.097 45.7031 206.46 49.5636C204.794 53.4393 203.969 57.6024 203.969 61.9521C203.969 66.3836 204.794 70.5658 206.46 74.3958C208.108 78.2239 210.422 81.639 213.298 84.5343C216.211 87.4468 219.603 89.7425 223.41 91.3815C227.247 93.0471 231.436 93.8714 235.838 93.8714C240.187 93.8714 244.358 93.0471 248.234 91.3815C252.071 89.7425 255.499 87.4335 258.414 84.5343C261.286 81.639 263.594 78.2239 265.241 74.4206C266.897 70.5658 267.735 66.3836 267.735 61.9521C267.735 57.6024 266.897 53.4393 265.241 49.5636C263.611 45.7031 261.286 42.2823 258.414 39.3889Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.106 79.7231C171.818 82.0074 169.107 83.8272 166.095 85.1273C160.088 87.7238 152.68 87.7391 146.595 85.0988C143.537 83.7834 140.877 81.9693 138.628 79.7231C136.373 77.4826 134.572 74.7985 133.256 71.7375C131.925 68.7032 131.267 65.4043 131.267 61.9474C131.267 58.5019 131.925 55.2239 133.256 52.1763C134.572 49.1191 136.373 46.4541 138.628 44.1946C140.877 41.9617 143.537 40.1514 146.595 38.8246C152.68 36.1938 160.08 36.1938 166.144 38.8246C169.192 40.1514 171.877 41.9617 174.106 44.1946C174.416 44.5163 174.804 44.7447 175.182 44.9141L179.639 40.3893C179.47 40.0467 179.268 39.744 178.953 39.4356C176.095 36.565 172.697 34.2655 168.841 32.5675C161.136 29.2248 151.605 29.2248 143.942 32.537C140.118 34.1798 136.701 36.4927 133.825 39.3861C130.925 42.2796 128.611 45.7022 126.962 49.5627C125.315 53.4365 124.484 57.6053 124.484 61.9474C124.484 66.3847 125.315 70.563 126.962 74.3949C128.611 78.2211 130.925 81.6419 133.825 84.5315C136.701 87.444 140.13 89.7435 143.942 91.3825C147.768 93.0444 151.957 93.8667 156.365 93.8667C160.769 93.8667 164.954 93.0444 168.791 91.3825C172.609 89.7435 176.038 87.4174 178.953 84.4877C179.031 84.423 179.059 84.345 179.113 84.2726L174.416 79.5156C174.336 79.5917 174.209 79.6317 174.106 79.7231Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M397.832 39.2073L411.314 67.169H384.356L397.832 39.2073ZM371.877 93.092L381.177 73.7878H414.495L423.727 93.092H431.251L402.375 32.6628C401.799 31.5415 401.01 30.7991 400.002 30.466C398.3 29.9007 397.374 29.9007 395.653 30.466C394.643 30.7991 393.854 31.5415 393.288 32.6799L364.312 93.092H371.877Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M556.777 93.0896V33.8844C556.777 32.9097 556.434 32.1064 555.786 31.4725C554.538 30.1895 552.209 30.1895 550.939 31.4725C550.323 32.1064 549.984 32.9097 549.984 33.8844V93.0896H556.777Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M508.965 30.8608L483.457 84.698L457.596 30.8608H450.062L478.893 91.2597C479.454 92.3847 480.243 93.1233 481.272 93.4393C482.122 93.7343 482.829 93.8695 483.457 93.8695C484.067 93.8695 484.759 93.7343 485.6 93.4393C486.617 93.1233 487.425 92.3847 487.986 91.2444L516.476 30.8608H508.965Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M656.684 30.8609V81.9036L606.144 32.005C605.583 31.4168 605.123 31.057 604.775 30.8609C603.676 30.2822 601.753 30.3869 600.665 31.4701C600.03 32.104 599.711 32.9073 599.711 33.8819V93.091H606.475V42.0273L657.007 91.9165C657.581 92.478 658.024 92.8302 658.401 93.0453C658.844 93.2985 659.41 93.4317 660.074 93.4317C661.038 93.4317 661.856 93.091 662.512 92.459C663.151 91.8118 663.485 90.9913 663.485 90.0148V30.8609H656.684Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M335.755 82.1068C333.555 80.346 328.754 75.6803 326.335 72.9753L321.878 68.0012C323.245 67.4758 324.536 66.802 325.765 65.9872C327.501 64.8299 328.982 63.4193 330.191 61.7803C331.42 60.1547 332.386 58.3443 333.078 56.4065C333.783 54.4629 334.144 52.3309 334.144 50.0961C334.144 47.4749 333.639 44.9831 332.64 42.6512C331.65 40.3136 330.3 38.2615 328.554 36.5388C326.833 34.816 324.795 33.4283 322.467 32.448C320.119 31.4562 317.632 30.9556 315.015 30.9556H294.943C293.994 30.9556 293.144 31.2868 292.517 31.9207C291.878 32.5565 291.555 33.3674 291.555 34.3478V93.0905H298.332V37.5724H314.557C316.244 37.5724 317.892 37.8903 319.396 38.549C320.91 39.2057 322.249 40.1251 323.397 41.2578C324.536 42.398 325.434 43.7362 326.092 45.2458C326.744 46.7591 327.09 48.3981 327.09 50.0961C327.09 51.7998 326.744 53.4388 326.092 54.9503C325.434 56.4598 324.536 57.8075 323.397 58.9478C322.26 60.0576 320.929 60.9941 319.396 61.649C317.892 62.2924 316.265 62.6483 314.557 62.6483H307.911L321.032 77.6505C322.908 79.7692 327.373 84.1817 329.393 86.0701C331.42 88.0022 333.827 90.1913 336.242 91.6247C344.268 96.4179 349.95 91.3068 349.95 91.3068C345.953 91.421 335.755 82.1068 335.755 82.1068Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M260.925 61.9518C260.925 65.4087 260.256 68.7057 258.925 71.742C257.6 74.8029 255.81 77.487 253.563 79.7275C251.306 81.9718 248.659 83.7878 245.588 85.0956C239.499 87.734 232.121 87.734 226.048 85.0956C223.002 83.7878 220.315 81.9718 218.056 79.7275C215.828 77.487 214.031 74.8029 212.703 71.742C211.382 68.7057 210.713 65.4087 210.713 61.9518C210.713 58.5063 211.382 55.2246 212.703 52.1788C214.031 49.1217 215.828 46.4509 218.056 44.1914C220.315 41.9585 223.002 40.1501 226.048 38.8271C229.094 37.5079 232.369 36.8245 235.809 36.8245C239.256 36.8245 242.546 37.5079 245.588 38.8271C248.659 40.1501 251.306 41.9585 253.563 44.1914C255.801 46.4509 257.592 49.1217 258.925 52.1788C260.256 55.2246 260.925 58.5063 260.925 61.9518ZM258.383 39.3886C255.47 36.4895 252.042 34.1785 248.204 32.5395C240.425 29.2102 231.005 29.2597 223.38 32.5395C219.572 34.1785 216.16 36.4895 213.268 39.3886C210.391 42.2821 208.068 45.7028 206.43 49.5633C204.763 53.439 203.938 57.6021 203.938 61.9518C203.938 66.3834 204.763 70.5655 206.43 74.3956C208.077 78.2237 210.391 81.6387 213.268 84.5341C216.179 87.4465 219.572 89.7423 223.38 91.3812C227.218 93.0469 231.407 93.8711 235.809 93.8711C240.157 93.8711 244.329 93.0469 248.204 91.3812C252.042 89.7423 255.47 87.4332 258.383 84.5341C261.254 81.6387 263.563 78.2237 265.211 74.4203C266.868 70.5655 267.706 66.3834 267.706 61.9518C267.706 57.6021 266.868 53.439 265.211 49.5633C263.582 45.7028 261.254 42.2821 258.383 39.3886Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.106 79.7231C171.82 82.0074 169.109 83.8272 166.097 85.1273C160.09 87.7238 152.68 87.7391 146.595 85.0988C143.537 83.7834 140.877 81.9693 138.63 79.7231C136.373 77.4826 134.572 74.7985 133.256 71.7375C131.927 68.7032 131.269 65.4043 131.269 61.9474C131.269 58.5019 131.927 55.2239 133.256 52.1763C134.572 49.1172 136.373 46.4522 138.63 44.1946C140.877 41.9617 143.537 40.1514 146.595 38.8246C152.68 36.1938 160.08 36.1938 166.144 38.8246C169.192 40.1514 171.879 41.9617 174.106 44.1946C174.418 44.5163 174.806 44.7428 175.184 44.9141L179.639 40.3893C179.47 40.0467 179.268 39.7421 178.953 39.4337C176.095 36.565 172.697 34.2655 168.841 32.5675C161.138 29.2248 151.605 29.2248 143.942 32.537C140.118 34.1779 136.701 36.4927 133.825 39.3861C130.925 42.2777 128.611 45.7022 126.962 49.5627C125.315 53.4365 124.484 57.6053 124.484 61.9474C124.484 66.3847 125.315 70.563 126.962 74.3949C128.611 78.2211 130.925 81.6419 133.825 84.5315C136.701 87.444 140.13 89.7435 143.942 91.3825C147.768 93.0444 151.957 93.8667 156.367 93.8667C160.769 93.8667 164.954 93.0444 168.791 91.3825C172.609 89.7435 176.038 87.4174 178.953 84.4877C179.031 84.423 179.061 84.345 179.113 84.2726L174.418 79.5156C174.336 79.5917 174.209 79.6317 174.106 79.7231Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M397.83 39.2073L411.314 67.169H384.354L397.83 39.2073ZM371.875 93.092L381.175 73.7859H414.493L423.727 93.092H431.251L402.373 32.6628C401.797 31.5415 401.01 30.7991 400 30.466C398.298 29.9007 397.372 29.9007 395.653 30.466C394.643 30.7991 393.852 31.5415 393.288 32.6799L364.312 93.092H371.875Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M556.777 93.0896V33.8844C556.777 32.9078 556.434 32.1064 555.786 31.4725C554.537 30.1895 552.207 30.1895 550.939 31.4725C550.323 32.1064 549.984 32.9078 549.984 33.8844V93.0896H556.777Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M508.965 30.8608L483.457 84.698L457.596 30.8608H450.062L478.893 91.2597C479.454 92.3847 480.243 93.1233 481.272 93.4393C482.122 93.7343 482.829 93.8695 483.457 93.8695C484.067 93.8695 484.759 93.7343 485.6 93.4393C486.617 93.1233 487.425 92.3847 487.986 91.2444L516.476 30.8608H508.965Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M656.684 30.8588V81.8996L606.142 32.0029C605.583 31.4147 605.123 31.0549 604.775 30.8588C603.674 30.2782 601.751 30.3848 600.665 31.468C600.03 32.1019 599.711 32.9033 599.711 33.8798V93.0889H606.473V42.0252L657.007 91.9144C657.581 92.4759 658.024 92.8281 658.401 93.0432C658.844 93.2964 659.41 93.4296 660.074 93.4296C661.038 93.4296 661.856 93.0889 662.512 92.4569C663.151 91.8097 663.485 90.9892 663.485 90.0127V30.8588H656.684Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M335.753 82.1067C333.553 80.3459 328.752 75.6802 326.333 72.9752L321.876 68.0012C323.243 67.4758 324.534 66.8019 325.763 65.9872C327.499 64.8298 328.98 63.4192 330.189 61.7803C331.418 60.1546 332.384 58.3443 333.076 56.4045C333.783 54.4629 334.143 52.3309 334.143 50.0961C334.143 47.4748 333.637 44.9811 332.638 42.6511C331.648 40.3135 330.298 38.2615 328.552 36.5387C326.831 34.8141 324.793 33.4283 322.467 32.4479C320.117 31.4562 317.632 30.9536 315.013 30.9536H294.941C293.992 30.9536 293.142 31.2848 292.515 31.9187C291.876 32.5564 291.555 33.3655 291.555 34.3458V93.0904H298.33V37.5724H314.555C316.242 37.5724 317.89 37.8884 319.396 38.547C320.908 39.2057 322.249 40.1251 323.395 41.2577C324.534 42.398 325.432 43.7362 326.09 45.2438C326.742 46.7591 327.088 48.3981 327.088 50.0961C327.088 51.7998 326.742 53.4388 326.09 54.9502C325.432 56.4597 324.534 57.8075 323.395 58.9477C322.258 60.0575 320.927 60.9941 319.396 61.6489C317.89 62.2904 316.263 62.6483 314.555 62.6483H307.909L321.03 77.6504C322.909 79.7691 327.371 84.1816 329.391 86.07C331.418 88.0021 333.825 90.1913 336.24 91.6247C344.266 96.4179 349.948 91.3068 349.948 91.3068C345.951 91.421 335.753 82.1067 335.753 82.1067Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M682.769 36.8564C683.826 36.8564 684.76 36.8031 684.76 35.6571C684.76 34.652 683.916 34.5283 683.106 34.5283H681.52V36.8564H682.769ZM680.358 33.4718H683.315C685.007 33.4718 685.924 34.1952 685.924 35.7085C685.924 37.0677 685.024 37.7016 683.863 37.8272L686.047 41.1223H684.886L682.769 37.9148H681.52V41.1223H680.358V33.4718ZM688.335 37.2619C688.335 34.2294 685.941 31.7776 682.965 31.7776C679.987 31.7776 677.593 34.2294 677.593 37.2619C677.593 40.2943 679.987 42.7461 682.965 42.7461C685.941 42.7461 688.335 40.2943 688.335 37.2619ZM682.965 30.7192C686.574 30.7192 689.497 33.647 689.497 37.2619C689.497 40.8749 686.574 43.8026 682.965 43.8026C679.352 43.8026 676.43 40.8749 676.43 37.2619C676.43 33.647 679.352 30.7192 682.965 30.7192Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.8365 95.4359C64.3634 92.8737 67.2327 86.9364 67.2327 78.9261V58.9023C67.2327 54.8781 68.5143 50.5646 71.8895 48.2594C87.3144 37.7268 99.0942 24.516 101.414 15.748C101.414 15.748 102.365 36.3068 92.8288 54.6973C87.3468 65.2584 63.0989 104.21 62.8365 104.615C62.5759 104.21 38.3261 65.2584 32.8498 54.6973C23.3081 36.3068 24.2608 15.748 24.2608 15.748C26.5806 24.516 38.3585 37.7268 53.7815 48.2594C57.1605 50.5646 58.444 54.8781 58.444 58.9023V78.9261C58.444 86.9364 61.3096 92.8737 62.8365 95.4359Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.898 79.9747C64.898 81.1131 63.9777 82.0325 62.8368 82.0325C61.7073 82.0325 60.7812 81.1131 60.7812 79.9747C60.7812 78.8402 61.7073 77.915 62.8368 77.915C63.9777 77.915 64.898 78.8402 64.898 79.9747Z"
      fill="black"
    />
  </svg>
);

export default CoravinLogo;
