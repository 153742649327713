import React from 'react';

import NaturesBakeryLogo from '../client-logos/NaturesBakeryLogo';
import CaseStudySection from '../sections/CaseStudySection';

const NaturesBakeryBackground = ({ className }) => (
  <div
    className={className}
    style={{
      backgroundImage: `linear-gradient(to right, rgba(184,212,225,0), rgba(184,212,225,0.4)), url("/static-images/natures_bakery_1866x1200_cartons_top_view.webp")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    &nbsp;
  </div>
);

const NaturesBakerySection = ({ textSide = 'left' }) => (
  <CaseStudySection textSide={textSide} BackgroundCover={NaturesBakeryBackground}>
    <h2>
      <NaturesBakeryLogo className="inline-block h-14 w-auto mb-8" />
    </h2>
    <p className="text-xl mb-7">
      &quot;Thank you for all your help supporting our transition to Hyvä on Magento 2. We appreciate it!&quot;
    </p>
    <p className="text-lg mb-10">
      Wyatt Brunk
      <br />
      eCommerce DTC Manager
    </p>
    <a href="/contact" className="inline-flex btn btn-sp-green">
      Get in touch
    </a>
  </CaseStudySection>
);

export default NaturesBakerySection;
