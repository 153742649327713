import React from 'react';

const RxbarLogo = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="layer"
    x="0px"
    y="0px"
    viewBox="200.1 274.4 251.7 103.2"
  >
    <rect x="200.1" y="274.4" width="251.7" height="103.2" />
    <path
      style={{ fill: '#fff' }}
      d="M245.4,314.4h5.5c5.4,0,7.5,1.7,7.5,4.5c0,2.8-2.1,4.5-7.5,4.5h-5.5V314.4z M251.4,342.4v-5.1h-3.9l-2.1-2.1  v-6.8h3.5l7.1,8.9v5.1h12v-5.4h-4.8l-7.3-9c5.7-1,8.7-4.1,8.7-9.2c0-6.2-4.6-9.6-13.4-9.6h-15.4v5.1h2.2l2.1,2.1v18.8l-2.1,2.1h-2.2  v5.1L251.4,342.4L251.4,342.4z M303.6,337.3l-10.7-11.8l10.2-11.1h2v-5.1h-13.4v5.1h2.6l1.4,1l-5.9,6.2l-5.7-6.2l1.4-1h2.6v-5.1h-14  v5.1h2l10.1,11.5l-10.5,11.4h-3.3v5.1h14.2v-5.1h-2.1l-1.4-1l6.4-6.6l6.1,6.6l-1.4,1H292v5.1h14.9v-5.1L303.6,337.3L303.6,337.3z   M323.1,327.7h5.9c5.5,0,7.8,1.8,7.8,4.8s-2.3,4.8-7.8,4.8h-5.9V327.7z M328.4,322.8h-5.2v-8.4h5.2c5,0,7.1,1.6,7.1,4.1  C335.5,321.2,333.3,322.8,328.4,322.8 M335.9,324.9c3.7-0.8,5.4-3.6,5.4-6.3c0-5.9-4.4-9.2-12.8-9.2h-17.2v5.1h4.2l2.1,2.1v18.8  l-2.1,2.1h-4.2v5.1h17.8c8.8,0,13.5-3.4,13.5-9.9C342.7,329.2,339.5,325.2,335.9,324.9 M367,326.5h-8l3.9-11.5L367,326.5z   M360.6,337.3H358l-2.1-1.7l1.4-3.9h11.5l1.4,3.9l-2.1,1.7h-2.6v5.1h15v-5.1H377l-10.4-28h-6.5l-10.4,28H346v5.1h14.6V337.3z   M392.9,314.4h5.5c5.4,0,7.4,1.7,7.4,4.5c0,2.8-2.1,4.5-7.4,4.5h-5.5V314.4z M399,342.4v-5.1H395l-2.1-2.1v-6.8h3.5l7.1,8.9v5.1h12  v-5.4h-4.8l-7.3-9c5.7-1,8.7-4.1,8.7-9.2c0-6.2-4.6-9.6-13.4-9.6h-15.4v5.1h2.2l2.1,2.1v18.8l-2.1,2.1h-2.2v5.1L399,342.4L399,342.4  z M418.6,305.2c0,2.6,2.1,4.8,4.8,4.8s4.8-2.2,4.8-4.8s-2.1-4.8-4.8-4.8C420.8,300.4,418.6,302.5,418.6,305.2 M419.3,305.2  c0-2.3,1.9-4.2,4.1-4.2c2.2,0,4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2C421.2,309.3,419.3,307.4,419.3,305.2 M422.7,303.7h0.7  c0.7,0,1,0.2,1,0.6c0,0.3-0.3,0.6-1,0.6h-0.7V303.7z M423.5,307.3v-0.7H423l-0.3-0.3v-0.9h0.5l0.9,1.1v0.7h1.5v-0.7H425l-0.9-1.1  c0.7-0.1,1.1-0.5,1.1-1.2c0-0.8-0.6-1.2-1.7-1.2h-2v0.7h0.3l0.3,0.3v2.4l-0.3,0.3h-0.3v0.7L423.5,307.3L423.5,307.3z"
    />
  </svg>
);

export default RxbarLogo;
