import React from 'react';

import CoravinLogo from '../client-logos/CoravinLogo';
import CaseStudySection from '../sections/CaseStudySection';

const CoravinBackground = ({ className }) => (
  <div
    className={className}
    style={{
      backgroundImage: `linear-gradient(to left, rgba(54,120,138,0), rgba(54,120,138,0.1), rgba(54,120,138,0.2), rgba(54,120,138,0.6)), url("/static-images/coravin_blue_bg.png")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    }}
  >
    &nbsp;
  </div>
);

const CoravinSection = ({ textSide = 'right' }) => (
  <CaseStudySection textSide={textSide} BackgroundCover={CoravinBackground}>
    <h2>
      <CoravinLogo className="inline-block h-10 w-auto mb-8" />
    </h2>
    <p className="text-xl mb-7">
      &quot;Thanks for the great detective work with our team. I really appreciate all the assistance, we have solved our
      issue!&quot;
    </p>
    <p className="text-lg mb-10">
      Calli Prendergast
      <br />
      VP IT
    </p>

    <a href="/contact" className="inline-flex btn btn-sp-green">
      Get in touch
    </a>
  </CaseStudySection>
);

export default CoravinSection;
